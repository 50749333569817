import React, { Component } from "react";
import auth from "services/auth";
import GridContainer from "components//Grid/GridContainer.js";
import GridItem from "components//Grid/GridItem.js";
//import Card from "../Card/Card.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import checatoken from "./checatoken";
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Assignment from "@material-ui/icons/Assignment";
import TabelaMotorista from "./TabelaMotorista";
import Primary from "components/Typography/Primary.js";
import Muted from "components/Typography/Muted";
import Button from "components/CustomButtons/Button.js";
import classNames from "classnames";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";

import CustomInput from "components/CustomInput/CustomInput.js";
import Swal from 'sweetalert2';

const styles = {
    cardTitle,
    pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center",
    },
    cardCategory: {
        margin: "0",
        color: "#999999",
    },
};

const flexContainerClasses = classNames({
    [classNames.flexContainer]: true,
    [classNames.horizontalDisplay]: true,
});

class recuperaSenha extends Component {
    state = {
        senhaAtual: "",
        senhaNova: "",
        senhaConfirma: ""
    };

    SalvaSenhaAtual(event) {
        this.setState({ senhaAtual: event.target.value });
    }

    SalvaSenhaNova(event) {
        this.setState({ senhaNova: event.target.value });
    }

    SalvaSenhaConfirma(event) {
        this.setState({ senhaConfirma: event.target.value });
    }



    async componentDidMount() {
        checatoken();
    }


    async TrocaSenha(){
        const chave = localStorage.getItem("lukos-token-fw");
        await auth.post("/faturaweb/trocarsenhapost", 	 
            { params: {
                     chave, // Parâmetros de URL
                   }}, 
            { headers: {
            senhaAtual: this.state.senhaAtual,
            senhaNova: this.state.senhaNova, 
            'Cache-Control': 'no-cache'
          }, }).then(response => {
            switch (response.status){
                case 200:
                    Swal.fire('Senha alterada', '', 'success') ;
                    break;
                case 201:
                    Swal.fire('OPS!', response.data, 'error');
                    break;
                default:
                    Swal.fire('OPS!', response.data, 'error')
            }
            })
        .catch(error => {
          console.log(error);
          Swal.fire('', error.response.data, 'error')
        })      
      }


    opensweetalert() {
        if (this.state.senhaNova.length < 4) {
            Swal.fire("A senha deve ter pelo menos 4 caracteres.", '', 'error');
            return;
        }

        if (this.state.senhaNova !== this.state.senhaConfirma) {
            Swal.fire("As senhas não correspondem.", '', 'error');
            return;
        }

        Swal.fire({
            title: 'Confirma as alterações?',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: `Não`,
            confirmButtonColor: '#578F3D',
            cancelButtonColor: '#d33',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                this.TrocaSenha();
            } else  {
                Swal.fire('Senha não alterada', '', 'error')
            }
        })
    }

    render() {
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <Card>
                            <CardHeader color="success" icon>
                                <CardIcon color="success">
                                    <Assignment />
                                </CardIcon>
                                <Muted>
                                    <h4>Trocar Senha</h4>
                                </Muted>
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                    labelText="Senha Atual"
                                    id="senhaAtual"
                                    name="senhaAtual"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: (event) => {
                                            this.SalvaSenhaAtual(event);
                                        },
                                        type: "password",
                                        autoComplete: "off"
                                    }}
                                />
                                <CustomInput
                                    labelText="Senha Nova"
                                    id="senhaNova"
                                    name="senhaNova"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: (event) => {
                                            this.SalvaSenhaNova(event);
                                        },
                                        type: "password",
                                        autoComplete: "off"
                                    }}
                                />
                                <CustomInput
                                    labelText="Confirme a senha"
                                    id="senhaConfirma"
                                    name="senhaConfirma"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange: (event) => {
                                            this.SalvaSenhaConfirma(event);
                                        },
                                        type: "password",
                                        autoComplete: "off"
                                    }}
                                />
                                <Button color="primary" onClick={() => this.opensweetalert()} >
                                    Trocar a senha
                                </Button>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}></GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default recuperaSenha;
