import React, { Component, } from "react";
import Datetime from "react-datetime";
import auth from "services/auth";
import GridContainer from "components//Grid/GridContainer.js";
import GridItem from "components//Grid/GridItem.js";
//import Card from "../Card/Card.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import checatoken from "./checatoken";
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Assignment from "@material-ui/icons/Assignment";
import TabelaFaturas from "./TabelaFaturas";
import Primary from "components/Typography/Primary.js";
import Muted from "components/Typography/Muted";
import Button from "components/CustomButtons/Button.js";
import classNames from "classnames";
import { Label, Row, Col, Input, FormGroup, Container } from "reactstrap";
import Icon from "@material-ui/core/Icon";
import CardFooter from "components/Card/CardFooter.js";
import DateRange from "@material-ui/icons/DateRange";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Swal from "sweetalert2";


const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

export const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

const flexContainerClasses = classNames({
  [classNames.flexContainer]: true,
  [classNames.horizontalDisplay]: true,
});

class Faturas extends Component {

  constructor(props) {
    super(props);

    const dataAtual = new Date();
    const dia = String(dataAtual.getDate()).padStart(2, '0');
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    const ano = dataAtual.getFullYear();
    //const dataFormatada = `${ano}-${mes}-${dia}`;
    const dataFormatada = `${dia}/${mes}/${ano}`;


    this.state = {
      motorista: [],
      faturas: [],
      edtStatus: "0",
      VencInicio: "",
      VencTermino: dataFormatada,
      FechInicio: "",
      FechTermino: ""
    };


    this.SalvaStatus = this.SalvaStatus.bind(this);
    this.SalvaVencInicio = this.SalvaVencInicio.bind(this);
    this.SalvaVencTermino = this.SalvaVencTermino.bind(this);
    this.SalvaFechInicio = this.SalvaFechInicio.bind(this);
    this.SalvaFechTermino = this.SalvaFechTermino.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  aguardando = (proximoFoco) => {
    Swal.fire({
      title: "Verificando dados",
      html: "Por favor, aguarde.",
      timerProgressBar: true,
      focusConfirm: false,
      didOpen: () => {
        Swal.showLoading();
      },
      didClose: () => {
        if (proximoFoco && document.getElementById(proximoFoco)) {
          document.getElementById(proximoFoco).focus();
        }
      }
    });
  };

  SalvaFechTermino(date) {
    try {
      this.setState({ FechTermino: date.format('YYYY-MM-DD') });
    }
    catch (error) {
      this.setState({ FechTermino: '' });
    }
  }

  SalvaFechInicio(date) {
    try {
      this.setState({ FechInicio: date.format('YYYY-MM-DD') });
    }
    catch (error) {
      this.setState({ FechInicio: '' });
    }
  }

  SalvaVencTermino(date) {
    try {
      this.setState({ VencTermino: date.format('YYYY-MM-DD') });
    }
    catch (error) {
      this.setState({ VencTermino: '' });
    }
  }

  SalvaVencInicio(date) {
    console.log(date);
    try {
      this.setState({ VencInicio: date.format('YYYY-MM-DD') });
    }
    catch (error) {
      this.setState({ VencInicio: '' });
    }
  }

  SalvaStatus(event) {
    this.setState({ edtStatus: event.target.value.toUpperCase() });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.getFaturas();
  }

  async componentDidMount() {
    checatoken();
    // const dataAtual = new Date();
    // this.setState({VencTermino : dataAtual.format('YYYY-MM-DD')})
    await this.getFaturas();

  }

  async getFaturas() {
    this.aguardando();
    const chave = localStorage.getItem("lukos-token-fw");
    const response = await auth.post("/faturaweb/GetFaturaspost", 	 
      { params: {
               chave, // Parâmetros de URL
             }},  {
      headers: {
        Status: this.state.edtStatus,
        VencIni: this.state.VencInicio,
        VencTer: this.state.VencTermino,
        FechIni: this.state.FechInicio,
        FechTer: this.state.FechTermino,
      },
    });
    this.setState({ faturas: response.data })
    Swal.close();
  }



  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
              </CardHeader>
              <CardBody>
                <Container justify="center">
                  <Primary>
                    <h4>Status do Faturamento</h4>
                  </Primary>
                </Container>
                <GridContainer justify="space-between"
                  alignItems="center"
                  direction="row" style={{ marginTop: '25px', marginBottom: '20px' }} >
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <Input
                        id="vAtivo"
                        name="vAtivo"
                        type="radio"
                        checked={this.state.edtStatus === "0"}
                        onChange={this.SalvaStatus}
                        value={0}
                      />
                      <span className="form-check-sign" />
                      Em Aberto
                    </Label>
                  </FormGroup>
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <Input
                        id="vAtivo"
                        name="vAtivo"
                        type="radio"
                        checked={this.state.edtStatus === "1"}
                        onChange={this.SalvaStatus}
                        value={"1"}
                      />
                      <span className="form-check-sign" />
                      Quitados
                    </Label>
                  </FormGroup>
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <Input
                        id="vAtivo"
                        name="vAtivo"
                        type="radio"
                        checked={this.state.edtStatus === "2"}
                        onChange={this.SalvaStatus}
                        value={"2"}
                      />
                      <span className="form-check-sign" />
                      Todos
                    </Label>
                  </FormGroup>
                </GridContainer>
              </CardBody>

            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
              </CardHeader>
              <CardBody>
                <Primary>
                  <h4>Data de Vencimento</h4>
                </Primary>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <InputLabel >Data Inicial</InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        id="edtVencInicio"
                        name="edtVencInicio"
                        closeOnSelect="true"
                        locale="pt-br"
                        dateFormat="DD/MM/yyyy"
                        onChange={this.SalvaVencInicio}
                        timeFormat={false}
                        inputProps={{ placeholder: "Escolha a data inicial" }}
                      />
                    </FormControl>
                    <br />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <InputLabel >Data Final</InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        id="edtVencTermino"
                        name="edtVencTermino"
                        closeOnSelect="true"
                        locale="pt-br"
                        dateFormat="DD/MM/yyyy"
                        onChange={this.SalvaVencTermino}
                        timeFormat={false}
                        inputProps={{ placeholder: "Escolha a data Final" }}
                        value={this.state.VencTermino}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>

              </CardBody>

            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
              </CardHeader>
              <CardBody>
                <Primary>
                  <h4>Data de Fechamento</h4>
                </Primary>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <InputLabel >Data Inicial</InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        id="edtFechInicio"
                        name="edtFechInicio"
                        closeOnSelect="true"
                        locale="pt-br"
                        dateFormat="DD/MM/yyyy"
                        onChange={this.SalvaFechInicio}
                        timeFormat={false}
                        inputProps={{ placeholder: "Escolha a data inicial" }}
                      />
                    </FormControl>
                    <br />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <InputLabel >Data Final</InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        id="edtFechTermino"
                        name="edtFechTermino"
                        closeOnSelect="true"
                        locale="pt-br"
                        dateFormat="DD/MM/yyyy"
                        onChange={this.SalvaFechTermino}
                        timeFormat={false}
                        inputProps={{ placeholder: "Escolha a data Final" }}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center"
                  alignItems="center"
                  direction="row" >
                  <Button color="behance" width="100%" onClick={this.handleSubmit}  style={{ marginTop: '35px',  marginBottom: '25px' }}> Localizar Faturas </Button>
                </GridContainer>
              </CardBody>

            </Card>

          </GridItem>


          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Assignment />
                </CardIcon>
                <Muted>
                  <h4>Faturas</h4>
                </Muted>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <Muted>
                        <h6>Status do Faturamento</h6>
                      </Muted>

                    </GridItem>
                  </GridContainer>
                </GridItem>
              </CardHeader>
              {/* <CardBody>
                <TabelaFaturas faturas={this.state.faturas} />
              </CardBody> */}
              <div style={{ overflowX: 'auto', overflowY: 'auto' }}>
                <CardBody>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                       <TabelaFaturas faturas={this.state.faturas} />
                  </GridContainer>
                  </GridItem>
                </CardBody>
              </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}></GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default Faturas;
