import React, { Component } from "react";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Contacts from "@material-ui/icons/Contacts";
import DateRange from "@material-ui/icons/DateRange";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Label, Row, Col, Input, FormGroup } from "reactstrap";
import Radio from "@material-ui/core/Radio";
import Swal from 'sweetalert2';
import auth from "services/auth";
import checatoken from './checatoken';
import NavPills from "./NavPills/NavPills.js";

import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { RepeatOneSharp } from "@material-ui/icons";
import TabelaVisualizaFatura from "./TabelaVisualizaFatura.js";
import TabelaCupons from "./TabelaCupons";
import TabelaItens from "./TabelaItens";
import TabelaServicos from "./TabelaServicos"
import axios from 'axios';
import { Servidor } from "services/auth";


export const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    }).format(value);


    function downloadFile(url, NomeArquivo) {
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', NomeArquivo);
        document.body.appendChild(link);
        link.click();
      });
    }    

class VisualizarFatura extends Component {
  constructor(props) {
    super(props);

    this.state = {
      TabelaCupom:[],
      TabelaItens:[],
      TabelaServicos:[],
      idFatura:0,
      DataAbertura:"",
      DataFechamento:"",
      Vencimento:"",
      CentroCusto:"",
      Desconto:0,
      Acrescimo:0,
      TotalFatura:0,
      Concluido:"",
      fileURL:"",
    };
  }

  aguardando = (proximoFoco) => {
    Swal.fire({
      title: "Verificando dados",
      html: "Por favor, aguarde.",
      timerProgressBar: true,
      focusConfirm: false,
      didOpen: () => {
        Swal.showLoading();
      },
      didClose: () => {
        if (proximoFoco && document.getElementById(proximoFoco)) {
          document.getElementById(proximoFoco).focus();
        }
      }
    });
  };

  async componentDidMount() {
    console.log("visualizar Fatura")
    checatoken();
    const { id } = this.props.match.params;
    if (typeof id === "undefined") {
      console.log("NOVO" + id);
    } else {
      console.log("Editar " + id);
    }

    this.setState({ idFatura: window.location.href.split("/")[5] });
     await this.DetalheFatura(id);
     await this.CuponsFatura(id);
     await this.ItensFatura(id);
     await this.ServicosFatura(id);
    Swal.close();
  }

  async DetalheFatura(id){
    const chave = localStorage.getItem("lukos-token-fw");
    await auth.post("/faturaweb/DetalheFaturapost",  { params: {
      chave, // Parâmetros de URL
    }},  {
      headers: {
        idFatura: id
      }, }).then(response => {
        this.setState({ idFatura: response.data.idFatura});
        this.setState({ DataAbertura: response.data.dataAbertura});
        this.setState({ DataFechamento: response.data.dataFechamento});
        this.setState({ Vencimento: response.data.vencimento});
        this.setState({ CentroCusto: response.data.centroCusto});
        this.setState({ Desconto: numberFormat(response.data.desconto)});
        this.setState({ Acrescimo: numberFormat(response.data.acrescimo)});
        this.setState({ TotalFatura: numberFormat(response.data.totalFatura)});
        this.setState({ Concluido: response.data.concluido});
          
        
        console.log(response.data); 
             })
    .catch(error => {
      console.log(error);
    })      
  }

  async CuponsFatura(id){
    const chave = localStorage.getItem("lukos-token-fw");
    await auth.post("/faturaweb/CuponsFaturapost", { params: {
      chave, // Parâmetros de URL
    }}, 
       {
      headers: {
        idFatura: id
      }, }).then(response => {
        this.setState({ TabelaCupom: response.data });
        console.log(response.data); 
             })
    .catch(error => {
      console.log(error);
    })      
    
  }

  async ItensFatura(id){
    const chave = localStorage.getItem("lukos-token-fw");
    await auth.post("/faturaweb/ItensFaturapost", 	 
      { params: {
               chave, // Parâmetros de URL
             }}, 
       {
      headers: {
        idFatura: id
      }, }).then(response => {
        this.setState({ TabelaItens: response.data });
        console.log(response.data); 
             })
    .catch(error => {
      console.log(error);
    })      
    
  }

  async ServicosFatura(id){
    const chave = localStorage.getItem("lukos-token-fw");
    await auth.post("/faturaweb/ServicosFaturapost", 	 
      { params: {
               chave, // Parâmetros de URL
             }}, 
      {
      headers: {
        idFatura: id
      }, }).then(response => {
        this.setState({ TabelaServicos: response.data });
        console.log(response.data); 
             })
    .catch(error => {
      console.log(error);
    })      
    
  }
  async voltar(){
    console.log('Voltar');
    window.history.back();
  }

  async GetBoleto(){
    const idFat = window.location.href.split("/")[5] ;
    //console.log('idFatura:'+idFat)
     const chave = localStorage.getItem("lukos-token-fw");
     await auth.post("/faturaweb/GetBoletopost",	 
      { params: {
               chave, 
             }}, 
       {
       headers: {
          token: chave,
          id: idFat
       }, }).then(response => {
                      console.log('ok - Boleto');
                      downloadFile( Servidor+'/faturaweb/GetFile/'+response.data, response.data);
              })
     .catch(error => {
      Swal.fire('', error.response.data, 'error');
    }) 
  }     

  async GetDanfe(){
    const idFat = window.location.href.split("/")[5] ;
    //console.log('idFatura:'+idFat)
     const chave = localStorage.getItem("lukos-token-fw");
     await auth.post("/faturaweb/GetDanfepost",  { params: {
      chave, // Parâmetros de URL
    }}, 
      {
       headers: {
          id: idFat
       }, }).then(response => {
                      console.log('ok - Danfe');
                      downloadFile( Servidor+'/faturaweb/GetFile/'+response.data, response.data);
              })
     .catch(error => {
       Swal.fire('', error.response.data, 'error');
    }) 
  }     

  async GetNFeXML(){
    const idFat = window.location.href.split("/")[5] ;
    //console.log('idFatura:'+idFat)
     const chave = localStorage.getItem("lukos-token-fw");
     await auth.post("/faturaweb/GetNFeXMpost",  { params: {
      chave, // Parâmetros de URL
    }},  {
       headers: {
          id: idFat
       }, }).then(response => {
                      console.log('ok - NFe');
                      downloadFile( Servidor+'/faturaweb/GetFile/'+response.data, response.data);
              })
     .catch(error => {
       Swal.fire('', error.response.data, 'error');
    }) 

  }

  async GetExtrato(){
    const idFat = window.location.href.split("/")[5] ;
    //console.log('idFatura:'+idFat)
     const chave = localStorage.getItem("lukos-token-fw");
     await auth.post("/faturaweb/GetRelatoriopost",  { params: {
      chave, // Parâmetros de URL
    }},  {
       headers: {
          id: idFat
       }, }).then(response => {
                      console.log('ok - NFe');
                      downloadFile( Servidor+'/faturaweb/GetFile/'+response.data, response.data);
              })
     .catch(error => {
       Swal.fire('', error.response.data, 'error');
    }) 

  }  

 async GetFatExcel(){
    const idFat = window.location.href.split("/")[5] ;
    //console.log('idFatura:'+idFat)
     const chave = localStorage.getItem("lukos-token-fw");
     await auth.post("/faturaweb/GetFatExcelpost",  { params: {
      chave, // Parâmetros de URL
    }},  {
       headers: {
          id: idFat
       }, }).then(response => {
                      console.log('ok - NFe');
                      downloadFile( Servidor+'/faturaweb/GetFile/'+response.data, response.data);
              })
     .catch(error => {
       Swal.fire('', error.response.data, 'error');
    }) 

  }  

  async GetViasAssinadas(){
    const idFat = window.location.href.split("/")[5] ;
    //console.log('idFatura:'+idFat)
     const chave = localStorage.getItem("lukos-token-fw");
     await auth.get("/faturaweb/GetViasAssinadas", {
       headers: {
          token: chave,
          id: idFat
       }, }).then(response => {
                      console.log('ok - GetViasAssinadas');
                      downloadFile( Servidor+'/faturaweb/GetFile/'+response.data, response.data);
              })
     .catch(error => {
       Swal.fire('', error.response.data, 'error');
    }) 

  }  

  
  render() {
    return (
      <div>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Contacts />
                </CardIcon>
                <h3 style={{ color: "black" }}>Fatura</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={6} sm={3} md={3}>
                        <CustomInput
                          // success={this.state.vPlaca}
                          //error={minLengthState === "error"}
                          id="idFatura"
                          name="idFatura"
                          labelText="Fatura Nº"
                          style={{ width: "100%" }}
                          enabled={false}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: this.state.idFatura,
                            type: "text",
                          }}
                        />
                      </GridItem>
                      <GridItem xs={6} sm={3} md={3}>
                        <CustomInput
                            id="dataAbertura"
                            name="dataAbertura"
                            labelText="Data Abertura"
                            style={{ width: "100%" }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: this.state.DataAbertura,
                              type: "text",
                            }}
                          />                        
                    </GridItem>                     
                    <GridItem xs={6} sm={3} md={3}>
                        <CustomInput
                            id="DataFechamento"
                            name="DataFechamento"
                            labelText="Data Fechamento"
                            style={{ width: "100%" }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: this.state.DataFechamento,
                              type: "text",
                            }}
                          />                        
                    </GridItem>                     
                    <GridItem xs={6} sm={3} md={3}>
                        <CustomInput
                            id="vencimento"
                            name="vencimento"
                            labelText="Data Vencimento"
                            style={{ width: "100%" }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: this.state.Vencimento,
                              type: "text",
                            }}
                          />                        
                    </GridItem>                     
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                    <GridItem xs={12} sm={5} md={5}>
                          <CustomInput
                                  id="CentroCusto"
                                  name="CentroCusto"
                                  labelText="Centro Custo"
                                  style={{ width: "100%" }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    value: this.state.CentroCusto,
                                    type: "text",
                                  }}
                                />  
                      </GridItem>
                      <GridItem xs={12} sm={2} md={2}>
                        <CustomInput
                                      id="Desconto"
                                      name="Desconto"
                                      labelText="Desconto"
                                      style={{ width: "100%" }}
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        value: this.state.Desconto,
                                        type: "text",
                                      }}
                                    />  
                    </GridItem>
                    <GridItem xs={12} sm={2} md={2}>
                        <CustomInput
                                      id="Acrescimo"
                                      name="Acrescimo"
                                      labelText="Acrescimo"
                                      style={{ width: "100%" }}
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        value: this.state.Acrescimo,
                                        type: "text",
                                      }}
                                    />  
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                                      id="TotalFatura"
                                      name="TotalFatura"
                                      labelText="Total Fatura"
                                      style={{ width: "100%" }}
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        value: this.state.TotalFatura,
                                        type: "text",
                                      }}
                                    />  
                    </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                            <FormGroup check className="form-check-radio">
                              <Label check>
                                <Input
                                  id="Concluido"
                                  name="Concluido"
                                  type="checkbox"
                                  checked={this.state.Concluido === "True"} 
                                  value={"True"}
                                />
                                <span className="form-check-sign" />
                                Faturamento Concluído
                              </Label>
                            </FormGroup>
                   </GridItem>
                </Row>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                    <Button color="primary" className="marginLeft" onClick={ this.voltar }>
                          Voltar
                        </Button>
                      </GridItem>
                      <GridItem xs={8} sm={8} md={8}>
                            <Button color="success" className="marginLeft" onClick={ this.GetBoleto }>
                              Boleto
                            </Button>
                            <Button color="success" className="marginLeft" onClick={ this.GetDanfe }>
                              Visualizar NFe
                            </Button>
                            <Button color="success" className="marginLeft" onClick={ this.GetNFeXML }>
                              Baixar XML - NF-e
                            </Button>
                            <Button color="success" className="marginLeft" onClick={ this.GetExtrato }>
                              Relatório PDF
                            </Button>
                            <Button color="success" className="marginLeft" onClick={ this.GetFatExcel }>
                              Relatório Excel
                            </Button>
                            <Button color="success" className="marginLeft" onClick={ this.GetViasAssinadas }>
                              Baixar Via Assinada
                            </Button>
                        </GridItem>
                        


                      <GridItem xs={12} sm={12} md={4}>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        
                      </GridItem>
                    </GridContainer>
                </GridItem>

              </CardBody>
            
            </Card>
          </GridItem>
          
          <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <h4>
                Detalhes da Fatura <small>  </small>
              </h4>
            </CardHeader>
            <CardBody>
               <NavPills
                color="warning"
                tabs={[
                  {
                    tabButton: "Cupons",
                    tabContent: ( 
                      <div style={{ overflowX: 'auto',  overflowY: 'auto'}}> 
                      <GridContainer>
                      <TabelaCupons cupons={this.state.TabelaCupom} />
                        </GridContainer>
                        </div>
                      
                    ),
                  },
                  {
                    tabButton: "Itens",
                    tabContent: (
                      <div style={{ overflowX: 'auto',  overflowY: 'auto'}}> 
                      <GridContainer>
                        <TabelaItens itens={this.state.TabelaItens}  />
                        </GridContainer>
                        </div>
                    ),
                  },
                  {
                    tabButton: "Serviços",
                    tabContent: (
                      <div style={{ overflowX: 'auto',  overflowY: 'auto'}}> 
                      <GridContainer>
                      <TabelaServicos servicos={this.state.TabelaServicos} />
                        </GridContainer>
                        </div>
                       
                    ),
                  },
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>     
        </GridContainer>
      </div>
    );
  }
}

export default VisualizarFatura;
