import auth from 'services/auth';
import history from '../history';




export default async function checaToken() {
        const chave = localStorage.getItem('lukos-token-fw');
        console.log('getchecaToken: Chave', chave);

        auth.get('/faturaweb/validatoken', {
            headers: {
                token: chave
            }
        })
        .then(res => {
            localStorage.setItem('lukos-token-fw', res.data.token);
        })
        // .catch(error => {
        //     localStorage.removeItem('lukos-token-fw');
        //     history.push('/login');
        // }) 
        
        const response  = await  auth.post('/faturaweb/validatokenPost', {
            params: {
                chave
            }
          }
        )

        console.log('Token Validado:'+ response.data.token);
        switch (response.status) {
        case 200:
          localStorage.setItem('lukos-token-fw', res.data.token);
          break;
        default:
            localStorage.removeItem('lukos-token-fw');
            history.push('/login');

      }
         const isLogged = !!localStorage.getItem('lukos-token-fw');

         if (isLogged === false) {
           history.push('/login');
         } 

        return (
            ''
        )

}


