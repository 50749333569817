import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "components/ReactTable/ReactTable.js";
import MediaQuery from 'react-responsive'
import Button from "components/CustomButtons/Button.js";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import auth from 'services/auth';
import Swal from 'sweetalert2';
import { Servidor } from "services/auth";
import axios from 'axios';

export const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

  function downloadFile(url, NomeArquivo) {
    console.log('URL '+url+'  Nome do Arquivo'+NomeArquivo)
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', NomeArquivo);
      document.body.appendChild(link);
      link.click();
    });
  }  
  

class TabelaContaCorrente extends Component {
  constructor(props) {
    super(props);
    this.state = { resumo: [] };
  }

  state = {
    color: 0,
  }


  async componentDidMount() {
      console.log('ContaCorrente');

  }

  async GetPDF(idCupom){
      console.log(idCupom);
     const chave = localStorage.getItem("lukos-token-fw");
     await auth.post("/faturaweb/GetSATPDFpost", { params: {
      chave, // Parâmetros de URL
    }}, 
       {
       headers: {
          vCupom: idCupom,
          'Cache-Control': 'no-cache'
       }, }).then(response => {
                      console.log( Servidor+'/faturaweb/GetFile/'+response.data);
                      downloadFile( Servidor+'/faturaweb/GetFile/'+response.data, response.data);
                      console.log('ok - Cupom PDF  '+response.data);
              })
     .catch(error => {
      Swal.fire('Erro PDF', error.response.data, 'error');
    }) 
    await auth.post("/faturaweb/GetSATXMLpost",  { params: {
      chave, // Parâmetros de URL
    }},  {
      headers: {
         vCupom: idCupom,
         'Cache-Control': 'no-cache'
      }, }).then(respons2 => {
              console.log('ok - Cupom XML '+respons2.data);
              downloadFile( Servidor+'/faturaweb/GetFile/'+respons2.data, respons2.data);
             })
    .catch(error => {
     Swal.fire('ERRO XML', error.respons2.data, 'error');
   }) 

  }     


  render() {
    return (<div>
      <MediaQuery minWidth={1224}>
      {/* <p>desktop</p> */}
      <ReactTable
        data={this.props.resumo.map((prop, key) => {
          return {
            idClienteContaCorrente: key,
            Data:           prop[1],
            COO:            prop[2],
            TotalCupom:     prop[3],
            SaldoAnterior:  prop[4],
            Saldo:          prop[5],
            TituloOrigem:   prop[6],
            Placa:          prop[7]
           };
        })}
        columns={[ 
          // { Header: "id", accessor: "idClienteContaCorrente"},
        { Header: "Data Cupom", accessor: "Data"},
        { Header: "Nº Cupom", accessor: "COO"},
        { Header: "Valor", accessor: "TotalCupom"},
        { Header: "Saldo Anterior", accessor: "SaldoAnterior"},
        { Header: "Saldo", accessor: "Saldo"},
        { Header: "Titulo Origem", accessor: "TituloOrigem"},
        { Header: "Placa", accessor: "Placa"},
         { Header: "", accessor: "actions", sortable: false, filterable: false}

        ]}
      />
      </MediaQuery>
      <MediaQuery minWidth={600} maxWidth={1223}>
        {/* <p>Mobile</p> */}
        <ReactTable
        data={this.props.resumo.map((prop, key) => {
          return {
            idClienteContaCorrente: key,
            Data:           prop[1],
            COO:            prop[2],
            TotalCupom:     prop[3],
            SaldoAnterior:  prop[4],
            Saldo:          prop[5],
            TituloOrigem:   prop[6],
            Placa:          prop[7]
           };
        })}
        columns={[ 
          // { Header: "id", accessor: "idClienteContaCorrente"},
        { Header: "Data Cupom", accessor: "Data"},
        { Header: "Nº Cupom", accessor: "COO"},
        { Header: "Valor", accessor: "TotalCupom"},
        { Header: "Saldo Anterior", accessor: "SaldoAnterior"},
        { Header: "Saldo", accessor: "Saldo"},
        { Header: "Titulo Origem", accessor: "TituloOrigem"},
        { Header: "Placa", accessor: "Placa"},
         { Header: "", accessor: "actions", sortable: false, filterable: false}

        ]}
      />
        </MediaQuery>
        <MediaQuery  maxWidth={599}>
          {/* <p>muito peqno</p> */}
        <ReactTable
        data={this.props.resumo.map((prop, key) => {
          return {
            idClienteContaCorrente: key,
            Data:           prop[1],
            COO:            prop[2],
            TotalCupom:     prop[3],
            SaldoAnterior:  prop[4],
            Saldo:          prop[5],
            TituloOrigem:   prop[6],
            Placa:          prop[7]
           };
        })}
        columns={[ 
          // { Header: "id", accessor: "idClienteContaCorrente"},
        { Header: "Data Cupom", accessor: "Data"},
        { Header: "Nº Cupom", accessor: "COO"},
        { Header: "Valor", accessor: "TotalCupom"},
        { Header: "Saldo Anterior", accessor: "SaldoAnterior"},
        { Header: "Saldo", accessor: "Saldo"},
        { Header: "Titulo Origem", accessor: "TituloOrigem"},
        { Header: "Placa", accessor: "Placa"},
         { Header: "", accessor: "actions", sortable: false, filterable: false}

        ]}
      />        
        </MediaQuery>
        
    </div>
    );
  }
}

TabelaContaCorrente.propTypes = {
  resumo: PropTypes.array
}

export default TabelaContaCorrente;
