import { LaptopWindows } from '@material-ui/icons';
import React, { Component } from 'react';
import auth from '../services/auth'
import history from "./../history";
import Swal from 'sweetalert2'

class UnidSel extends Component {

    aguardando = (proximoFoco) => {
        Swal.fire({
          title: "Verificando dados",
          html: "Por favor, aguarde.",
          timerProgressBar: true,
          focusConfirm: false,
          didOpen: () => {
            Swal.showLoading();
          },
          didClose: () => {
            if (proximoFoco && document.getElementById(proximoFoco)) {
              document.getElementById(proximoFoco).focus();
            }
          }
        });
      };

    state = {
        data: {
            idUnidOPeVinculo: '',
            unidOpe: 'sel',
        }
    };
   
    async getDadosUnidade() {

         const chave = localStorage.getItem('lukos-token-fw');
         console.log('getDadosUnidade: Chave', chave);    
       //  this.aguardando();
        
        const response = await auth.post('/faturaweb/unidselpost',{
            params: {
                chave
            }
          }
        )

        switch (response.status) {
        case 200:
            console.log(response.data);
            this.setState({ data: response.data })
        break;
       
        }
    };
    
    // async getDadosUnidade() {
    //     const chave = localStorage.getItem('lukos-token-fw');
    //     console.log('getDadosUnidade: Chave', chave);
        
    //     try {
    //         const response = await auth.get('/faturaweb/unidsel', {
    //             headers: {
    //                 token: chave, // Inclui o token no cabeçalho
    //             },
    //         });
    //         console.log(response.data);
    //         this.setState({ data: response.data });
    //     } catch (error) {
    //         console.error('Erro ao buscar dados da unidade:', error);
    //     }
    // }

    async componentDidMount() {
        await this.getDadosUnidade();
        Swal.close();
    };

    render() {
        return (<div>{this.state.data.unidOpe}</div>    
        );
    }

};

export default UnidSel;