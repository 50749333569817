import React, { Component } from 'react';
import auth from 'services/auth';
import GridContainer from "components//Grid/GridContainer.js";
import GridItem from "components//Grid/GridItem.js";
//import Card from "../Card/Card.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import checatoken from './checatoken';
import CardBody from "components/Card/CardBody.js";
//import {Line} from 'react-chartjs-2';

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Muted from './Typography/Muted';
import Assignment from "@material-ui/icons/Assignment";
import TabelaContaCorrente from "./TabelaContaCorrente.js";
import { Servidor } from "services/auth";


import Button from "components/CustomButtons/Button.js";
import Swal from 'sweetalert2';


export const numberFormat = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);

export const numberFormat2 = (value) =>
  new Intl.NumberFormat('pt-BR').format(value);



class CompContaCorrente extends Component {



  state = {
    data: {
      qtdeCupons: 0,
      valorTotalAberto: 0,
      ticketMedio: 0
    },
    tabelaresumo: [],
    Carregando: true,
    shoje: '',
  }


  tick() {
    this.setState(state => ({
      seconds: state.seconds + 1
    }));
    //  this.Recarrega();
  }

  async hideAlert() {
    console.log('hide')
  };

  async componentDidMount() {
    checatoken();
    const vAcesso = localStorage.getItem('acesso');
    if (vAcesso === null) {
      this.setState({ vAcesso: '0' })

    }

    if (vAcesso !== '0') {
      this.getResumo();
      this.getDadosMotorista();
    }

    if (vAcesso === '1') {
      this.setState({ Carregando: false });

    }

    await this.ChecaAcesso();
  };


  async ChecaAcesso() {
    const vAcesso = localStorage.getItem('acesso');
    if (vAcesso !== '1') {
      localStorage.setItem('acesso', '1');
      window.location.reload(false);
    }
  }

  async getResumo() {
    const chave = localStorage.getItem('lukos-token-fw');
    const response = await auth.post('/faturaweb/ResumoContaCorrentepost',  { params: {
      chave, // Parâmetros de URL
    }} );
    console.log(response);
    this.setState({ data: response.data });
  }

  async getDadosMotorista() {
    const chave = localStorage.getItem("lukos-token-fw");
    const response = await auth.post("/faturaweb/GetTabelaContaCorrentepost", { params: {
      chave, // Parâmetros de URL
    }} );
    this.setState({ tabelaresumo: response.data });
  }

  async Recarrega() {
    clearInterval(this.interval);
    this.interval = setInterval(() => this.tick(), 60000);
  }

  async getResumoXLS() {
    const chave = localStorage.getItem("lukos-token-fw");
    await auth.post(Servidor + "/faturaweb/GetTabelaContaCorrenteXLSpost", 	 
      { params: {
               chave, // Parâmetros de URL
             }}, ).then(res => {
      console.log(res.data);
      window.open(Servidor + "/faturaweb/GetFile/" + res.data);
    }
    )
  };


  render() {
    return (<div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Icon>attach_money</Icon>
              </CardIcon>
              <br></br>
              <Muted>
                <h6>Saldo Atual</h6>
                <h3>
                  {numberFormat(this.state.data.valorTotalAberto)}
                </h3>
              </Muted>
            </CardHeader>
            <CardFooter stats>
              <DateRange />
              Saldo disponível
            </CardFooter>
          </Card>
        </GridItem>
       
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <Muted>
                <h4>Resumo de Cupons</h4>
              </Muted>
              <Button style={{ float: 'right' }}
                color="success"
                className="marginLeft"
                onClick={() => this.getResumoXLS()}
              >
                Baixar em Excel
              </Button>
            </CardHeader>
            <div style={{ overflowX: 'auto',  overflowY: 'auto'}}> 
            <CardBody>
            <GridContainer>
                <TabelaContaCorrente resumo={this.state.tabelaresumo} />
              </GridContainer>
            </CardBody>
            </div> 
          </Card>
        </GridItem>
      </GridContainer>
    </div>
    )
  };


}


export default CompContaCorrente;