import React, { useState } from 'react'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import history from "../../history";
// import LockOutline from "@material-ui/icons/LockOutline";



// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Logo from './retaguarda_64.png';
import auth from "./../../services/auth";
import Swal from 'sweetalert2';

import Muted from "components/Typography/Muted.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

//export default function LoginPage() {
const LoginPage = () => {

  const [alert, setAlert] = React.useState(null);
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const [myUser, setUser] = useState();

  const handleInputChange = ev => {
    let name = [ev.target.id];
    let value = ev.target.value;
    setUser(
      { ...myUser, [name]: value }
    );
  }


  const basicAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Usuário ou senha inválida."
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
      >
        verifique os dados e tente novamente. =D
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const ChecaLogin = ev => {
    localStorage.removeItem('lukos-token-fw');
    ev.preventDefault();
    auth.post('/faturaweb/login', {
      params: {
        Usuario: myUser,
        host: window.location.href
      }
    }

    )
      .then(res => {
        const token = res.data.token;
        console.log(token); // Verificar se o token está correto
        try {
          localStorage.setItem('lukos-token-fw', token);
          localStorage.setItem('acesso', '1');
          console.log('Salvou o token');
          console.log('Valor do token no localStorage:', localStorage.getItem('lukos-token-fw'));
      } catch (error) {
          console.error('Erro ao salvar no localStorage:', error);
      }
        SalvaMenu(); // Certifique-se de que SalvaMenu usa o token do localStorage
      })
      .catch(error => {
        basicAlert();
        console.log(error);
        //                              swal("OPS!", "Usuário e/ou senha inválidos.", "error");
      })
  }

  const RecuperaSenha = ev => {
    ev.preventDefault();
    console.log('Chamou');
    auth.post('/faturaweb/RecuperarSenha', {
      params: {
        Usuario: myUser,
        host: window.location.href
      }
    }

    )
      .then(res => {
        Swal.fire("", "Enviamos sua senha por e-mail.", "success");
      })
      .catch(error => {
        console.log(error);
        Swal.fire('', error.response.data, 'error')
      })
  }

  const SalvaMenu = () => {
    const chave = localStorage.getItem('lukos-token-fw');
    console.log(chave)
    if (chave) {
      history.push('/');
    }
  }



  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="white"
              >
                <img src={Logo} alt="logo" />
                <Muted>
                  <h4>Fatura WEB</h4>
                </Muted>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  name="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "email",
                    onChange: (ev) => handleInputChange(ev),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  name="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: (ev) => handleInputChange(ev),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              {alert}
              <Button color="behance" simple onClick={RecuperaSenha}>
                Esqueci a senha
              </Button>

              <CardFooter className={classes.justifyContentCenter}>
                <Button color="primary" className={classes.marginRight} onClick={ChecaLogin} >
                  Entrar
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
  ;

export default LoginPage;