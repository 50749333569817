import React, { Component } from "react";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Contacts from "@material-ui/icons/Contacts";
import DateRange from "@material-ui/icons/DateRange";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Label, Row, Col, Input, FormGroup } from "reactstrap";
import Radio from "@material-ui/core/Radio";
import Swal from 'sweetalert2';
import auth from "services/auth";
import checatoken from './checatoken';

import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { RepeatOneSharp } from "@material-ui/icons";

class EditarMotorista extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idMotorista: 0,
      idCliente: 0,
      edtDocumento: "",
      edtNome: "",
      edtSenha: "",
      edtAtivo: "",
    };

    this.SalvaDocumento = this.SalvaDocumento.bind(this);
    this.SalvaNome = this.SalvaNome.bind(this);
    this.SalvaSenha = this.SalvaSenha.bind(this);
    this.SalvaAtivo = this.SalvaAtivo.bind(this);
  }

  async componentDidMount() {
    checatoken();
    const { id } = this.props.match.params;
    if (typeof id === "undefined") {
      console.log("NOVO" + id);
    } else {
      console.log("Editar " + id);
    }

    this.setState({ idMotorista: window.location.href.split("/")[5]});
  
    await this.DadosMotorista();
  }

  async DadosMotorista(){
    console.log('chamou');
    const { id } = this.props.match.params;
    console.log('Carregando Dados '+id);
    const chave = localStorage.getItem("lukos-token-fw");
    await auth.post("/faturaweb/GetDadosMotoristapost",  { params: {
      chave, // Parâmetros de URL
    }}, {
      headers: {
        idMotorista: id,
        'Cache-Control': 'no-cache'
      }, }).then(response => {
        this.setState({idMotorista : response.data.idMotorista});
        this.setState({idCliente : response.data.idCliente});
        this.setState({edtDocumento : response.data.documento});
        this.setState({edtNome : response.data.nome});
        this.setState({edtSenha : response.data.senha});
        this.setState({edtAtivo: response.data.ativo})
        
        
        console.log(response.data); 
             })
    .catch(error => {
      console.log(error);
      Swal.fire('', error.response.data, 'error')
    })      
  }


  SalvaAtivo(event) {
    console.log(event.target.value);
    this.setState({ edtAtivo: event.target.value});
  }

  SalvaDocumento(event) {
    this.setState({ edtDocumento: event.target.value.toUpperCase() });
  }

  SalvaNome(event) {
    this.setState({ edtNome: event.target.value.toUpperCase() });
  }

  SalvaSenha(event) {
    this.setState({ edtSenha: event.target.value.toUpperCase() });
  }

  async EnviaDadosMotorista(){
    const chave = localStorage.getItem("lukos-token-fw");
    await auth.post("/faturaweb/GetSalvaMotoristapost",
      { params: {
        chave, // Parâmetros de URL
      }},  {
      headers: {
                idMotorista : this.state.idMotorista,
                Nome : this.state.edtNome,
                Documento : this.state.edtDocumento,
                Senha : this.state.edtSenha,
                Ativo: this.state.edtAtivo
              },
      }).then(res => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-center',
          timer: 3000,
          timerProgressBar: true
        })
        Toast.fire('Sucesso', res.data, 'success').then((result) => {
                                                             this.voltar();
                                                           })
        })
        .catch(error => {
          console.log(error);
          Swal.fire('OPS!', error.response.data, 'error')
        })
    };


  teste(){
    this.EnviaDadosMotorista();
  }
  
  opensweetalert()
  {
  
      Swal.fire({
      title: 'Confirma as alterações?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: `Não`,
      confirmButtonColor: '#578F3D',
      cancelButtonColor: '#d33',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) { 
        this.teste();
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  async voltar(){
    console.log('Voltar');
    window.location.href = '/admin/Motorista'
  }

  
  render() {
    return (
      <div>
        <h1>
        </h1>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Contacts />
                </CardIcon>
                <h3 style={{ color: "black" }}>Cadastro de Motorista</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          success={this.state.vPlaca}
                          //error={minLengthState === "error"}
                          id="edtDocumento"
                          name="edtDocumento"
                          labelText="Documento"
                          style={{ width: "100%" }}
                          onChange={this.SalvaDocumento}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              this.SalvaDocumento(event);
                            },
                            value: this.state.edtDocumento,
                            type: "number",
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                            //success={this.state.edtNome}
                            //error={minLengthState === "error"}
                            id="edtNome"
                            name="edtNome"
                            labelText="Nome Motorista"
                            style={{ width: "100%" }}
                            onChange={this.SalvaNome}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              onChange: (event) => {
                                this.SalvaNome(event);
                              },
                              value: this.state.edtNome,
                              type: "text",
                            }}
                          />                        
                    </GridItem>                     
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          //success={this.state.vPlaca}
                          //error={minLengthState === "error"}
                          id="edtSenha"
                          name="edtsenha"
                          labelText="Senha"
                          style={{ width: "100%" }}
                          onChange={this.edtSenha}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              this.SalvaSenha(event);
                            },
                            value: this.state.edtSenha,
                            type: "Password",
                            autoComplete: "off",
                          }}
                        />
                      </GridItem>
                      
                    <GridItem xs={12} sm={6} md={6}>
                    <h6>Motorista Ativo</h6>
                    <Col className="checkbox-radios" sm="10">
                            <FormGroup check className="form-check-radio">
                              <Label check>
                                <Input
                                  id="vAtivo"
                                  name="vAtivo"
                                  type="radio"
                                  checked={this.state.edtAtivo === "True"} 
                                  onChange={this.SalvaAtivo}  
                                  value={"True"}
                                />
                                <span className="form-check-sign" />
                                Sim
                              </Label>
                            </FormGroup>
                        </Col>
                        <Col className="checkbox-radios" sm="10">
                            <FormGroup check className="form-check-radio">
                              <Label check>
                                <Input
                                  id="vAtivo"
                                  name="vAtivo"
                                  type="radio"
                                  checked={this.state.edtAtivo === "False"}
                                  onChange={this.SalvaAtivo}
                                  value={"False"}
                                />
                                <span className="form-check-sign" />
                                Não
                              </Label>
                            </FormGroup>
                        </Col>

                    </GridItem>
                    </GridContainer>
                  </GridItem>
                  
                </Row>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <Button color="primary" className="marginLeft" onClick={ this.voltar }>
                          Voltar
                        </Button>
                        </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <Button color="success" className="marginLeft" onClick={() => this.opensweetalert()}>
                          Salvar
                        </Button>
                      </GridItem>
                    </GridContainer>
                </GridItem>

              </CardBody>
              <CardFooter stats>
                <DateRange />
                Atualizando dados do motorista.
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default EditarMotorista;
