import React, { Component } from 'react';
import auth from '../../services/auth';
import history from "../../history";


export default class Unidade extends Component {
  constructor() {
    super();
    this.state = {
      UnidSel: '',
      _unidade: [],
    }
  }

  Toolbar() {
    return (
      <div>
        Unidade
      </div>
    );
  }

  async getDadosSel() {
    const chave = localStorage.getItem('lukos-token-fw');
    console.log('getDadosSel: Chave', chave);
    const response = await auth.post('/faturaweb/unidselpost', {
      params: {
        chave
        }
      }
    )
    if (response.status === 200) {
    this.setState({ UnidSel: response.data.unidOpe });
    }
  }

  async componentDidMount() {
    await this.getunidade();
    await this.getDadosSel();
  }

  async getunidade() {
    const chave = localStorage.getItem('lukos-token-fw');
    const response = await auth.post('/faturaweb/uniddisppost', {
      params: {
        chave
        }
      }
    )
    if (response.status === 200) {
    this.setState({ _unidade: response.data });
    }
  }

  async SetaUnidade(id) {
    const chave = localStorage.getItem('lukos-token-fw');
    console.log('UnidadeSelecionada'+{id});
    const response = await auth.post(`/faturaweb/setaunid/'${id}`,{
      params: {
        chave
        }
      }
    )
    if (response.status === 200) {
    localStorage.setItem('lukos-token-fw', response.data.token);
    history.push('/');
    }

  }

  render() {
    return (<div>
      { this.state._unidade.map(unid => (
        <li className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root makeStyles-dropdownItem-78 MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button" 
            tabindex="0" 
            role="menuitem" 
            aria-disabled="false" 
            onClick={() => this.SetaUnidade(unid.idUnidOPeVinculo)} 
            key={unid.idUnidOPeVinculo} >
                {unid.unidOpe}
            <span classNa="MuiTouchRipple-root">
            </span>
          </li> 
      )
      )

      }
    </div>
    );
  }
};
